/*
 * Created in April 2010
 * by AmicaNoctis
 * info@amicanoctis.de
 * amica@php-resource.de
 */
.kennzeichen,
.kennzeichen * {
    margin: 0;
    padding: 0;
    font-size: 40px;
    display: block;
}

.kennzeichen {
    height: 60px;
    background-position: -390px -60px;
}

.kennzeichen,
.schild,
.siegel,
.kennzeichen span span {
    color: transparent;
    background-image: url('kennzeichen-klein.png');
    background-repeat: no-repeat;
}

.kreis,
.plakette,
.siegel,
.buchstaben,
.ziffern,
.kennzeichen span {
    float: left;
}

.schild,
.kreis,
.plakette,
.siegel,
.buchstaben,
.ziffern {
    position: relative;
}

.kreis span,
.buchstaben span,
.ziffern span {
    width: 30px;
    height: 45px;
}

.plakette span {
    width: 20px;
    height: 20px;
}

.siegel span {
    width: 30px;
    height: 30px;
    font-size: 25px;
}

.kennzeichen span span {
    color: #ddd;
    font-size: 3px;
}

.schild {
    height: 60px;
    min-width: 300px;
    margin: 0 0 0 30px;
    background-position: right -165px;
}

.kreis {
    left: 5px;
    top: 7.5px;
}

.plakette {
    left: 10px;
    top: 5px;
    width: 25px;
}

.siegel {
    left: -20px;
    top: 26px;
    height: 30px;
    background-position: -390px -30px;
}

.buchstaben {
    left: -20px;
    top: 7.5px;
}

.ziffern {
    left: -10px;
    top: 7.5px;
}

.orange {
    background-position: -390px -120px;
}

.blau {
    background-position: -410px -120px;
}

.gelb {
    background-position: -430px -120px;
}

.braun {
    background-position: -390px -145px;
}

.rosa {
    background-position: -410px -145px;
}

.gruen {
    background-position: -430px -145px;
}

.datum {
    position: absolute;
    left: 0px;
    background-position: -450px -145px;
}

.bw {
    background-position: 0px -1px;
}

.by {
    background-position: -30px -1px;
}

.be {
    background-position: -60px -1px;
}

.bb {
    background-position: -90px -1px;
}

.hb {
    background-position: -120px -1px;
}

.hh {
    background-position: -150px -1px;
}

.XX {
    background-position: -450px -40px;
}

.he {
    background-position: -180px -1px;
}

.mv {
    background-position: -210px -1px;
}

.ni {
    background-position: -240px -1px;
}

.nw {
    background-position: -270px -1px;
}

.rp {
    background-position: -300px -1px;
}

.sl {
    background-position: -330px -1px;
}

.sn {
    background-position: -360px -1px;
}

.st {
    background-position: -390px -1px;
}

.sh {
    background-position: -420px -1px;
}

.th {
    background-position: -450px -1px;
}

.a {
    background-position: 0px -30px;
}

.b {
    background-position: -30px -30px;
}

.c {
    background-position: -60px -30px;
}

.d {
    background-position: -90px -30px;
}

.e {
    background-position: -120px -30px;
}

.f {
    background-position: -150px -30px;
}

.g {
    background-position: -180px -30px;
}

.h {
    background-position: -210px -30px;
}

span.i {
    background-position: -240px -30px;
    width: 22.5px;
}

.j {
    background-position: -270px -30px;
}

.k {
    background-position: -300px -30px;
}

.l {
    background-position: -330px -30px;
}

.m {
    background-position: -360px -30px;
}

.n {
    background-position: 0px -75px;
}

.o {
    background-position: -30px -75px;
}

.p {
    background-position: -60px -75px;
}

.q {
    background-position: -90px -75px;
}

.r {
    background-position: -120px -75px;
}

.s {
    background-position: -150px -75px;
}

.t {
    background-position: -180px -75px;
}

.u {
    background-position: -210px -75px;
}

.v {
    background-position: -240px -75px;
}

.w {
    background-position: -270px -75px;
}

.x {
    background-position: -300px -75px;
}

.y {
    background-position: -330px -75px;
}

.z {
    background-position: -360px -75px;
}

.n0 {
    background-position: 0px -120px;
}

.n1 {
    background-position: -30px -120px;
}

.n2 {
    background-position: -60px -120px;
}

.n3 {
    background-position: -90px -120px;
}

.n4 {
    background-position: -120px -120px;
}

.n5 {
    background-position: -150px -120px;
}

.n6 {
    background-position: -180px -120px;
}

.n7 {
    background-position: -210px -120px;
}

.n8 {
    background-position: -240px -120px;
}

.n9 {
    background-position: -270px -120px;
}

.ae {
    background-position: -300px -120px;
}

.oe {
    background-position: -330px -120px;
}

.ue {
    background-position: -360px -120px;
}
